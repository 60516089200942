import { h } from 'preact';
import { Router } from 'preact-router';

import Home from '../routes/Home';
import About from '../routes/About';

const App = () => (
	<div id="app" className="bg-zinc-50">
		{/* <Header /> */}
		<Router>
			<Home path="/" />
			<About path="/presentation" />
		</Router>
	</div>
)

export default App;
